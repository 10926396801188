<template lang="html">
  <div class="my-4 px-10 py-1">
    <ol class="flex space-x-4 mb-3">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>&gt;</li>
      <li class="font-bold">
        <router-link to="your-green-options"> Your Green Options </router-link>
      </li>
    </ol>

    <div class="grid grid-cols-3 2xl:gap-5 gap-3">
      <router-link to="/renewable-options/buy-recs" :class="styles.card">
        <p class="text-center">Buy RECs</p>
      </router-link>
      <router-link
        to="/setting-information/your-green-options/contact-sj"
        :class="styles.card"
      >
        <p class="text-center">Buy Carbon<br />Credits</p>
      </router-link>
      <router-link
        to="/setting-information/your-green-options/contact-sj"
        :class="styles.card"
      >
        <p class="text-center">Buy Corporate<br />PPAs</p>
      </router-link>
      <router-link
        to="/setting-information/your-green-options/contact-sj"
        :class="styles.card"
      >
        <p class="text-center">Install Solar PV<br />Systems</p>
      </router-link>
      <router-link
        to="/setting-information/your-green-options/contact-sj"
        :class="styles.card"
      >
        <p class="text-center">
          Install other<br />Renewable Energy<br />Systems
        </p>
      </router-link>
      <router-link
        to="/setting-information/your-green-options/contact-sj"
        :class="styles.card"
      >
        <p class="text-center">Contact<br />Surbana Jurong</p>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="css" module="styles">
.card {
  @apply p-5 rounded-xl font-semibold text-xl h-40 flex items-center justify-center;
  color: #92d050;
  box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.4);
  border: solid 1px #5e5e5e;
  background-color: #595959;
}
</style>
